// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-write-for-us-js": () => import("./../src/pages/contact/write-for-us.js" /* webpackChunkName: "component---src-pages-contact-write-for-us-js" */),
  "component---src-pages-get-quote-js": () => import("./../src/pages/get-quote.js" /* webpackChunkName: "component---src-pages-get-quote-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-js": () => import("./../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-scholarship-js": () => import("./../src/pages/scholarship.js" /* webpackChunkName: "component---src-pages-scholarship-js" */),
  "component---src-templates-blog-page-blog-page-js": () => import("./../src/templates/blogPage/blogPage.js" /* webpackChunkName: "component---src-templates-blog-page-blog-page-js" */),
  "component---src-templates-category-category-js": () => import("./../src/templates/category/category.js" /* webpackChunkName: "component---src-templates-category-category-js" */),
  "component---src-templates-location-page-location-page-js": () => import("./../src/templates/location-page/location-page.js" /* webpackChunkName: "component---src-templates-location-page-location-page-js" */),
  "component---src-templates-post-post-js": () => import("./../src/templates/post/post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-templates-scholarship-winner-page-scholarship-winner-page-js": () => import("./../src/templates/scholarshipWinnerPage/scholarshipWinnerPage.js" /* webpackChunkName: "component---src-templates-scholarship-winner-page-scholarship-winner-page-js" */),
  "component---src-templates-service-page-service-page-js": () => import("./../src/templates/servicePage/servicePage.js" /* webpackChunkName: "component---src-templates-service-page-service-page-js" */)
}

